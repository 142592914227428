<template>
  <div>
    <label :for="id" class="w-full block">{{ label }}</label>
    <input
      :id="id"
      class="w-10 h-10 rounded-full mb-3"
      type="color"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'Pick your color:'
    },
    id: {
      type: String,
      default: 'color_picker'
    }
  },
  emits: ['update:modelValue']
}
</script> 