<template>
  <div id="fractal-sierpinski" class="fractal-content flex flex-col md:flex-row">
    <!-- Control Panel -->
    <div class="w-full md:w-64 border-b md:border-e md:border-b-0 bg-white text-black">
      <div class="px-4 py-6 text-left">
        <ul class="mt-6 space-y-1">
          <li class="flex flex-col items-center md:items-start">
            <IterationSlider
              v-model="depth"
              :max="11"
              :min="1"
              :step="1"
            />

            <ColorPicker
              v-model="color"
            />

            <button
              class="w-full md:w-2/3 block mb-2"
              @click="drawFractal"
              type="button"
              title="Draw the fractal"
            >
              Draw
            </button>
            <button
              class="w-full md:w-2/3 block"
              @click="resetCanvas"
              type="button"
              title="Reset the canvas"
            >
              Reset
            </button>
          </li>
        </ul>
      </div>
      <p class="p-4 text-xs text-gray-800 text-left max-w-prose mx-auto md:mx-0">
        This is a fractal that is an equilateral triangle iteratively subdivided into smaller equilateral triangles.
        It's named after the Polish mathematician Wacław Sierpiński.
        Set the depth. If the depth is greater than 0, it calls itself to draw three smaller triangles; if the depth is 0, it draws a single triangle.
        I set the depth limit to 11. Why? Your browser would crush.
        Draw your Sierpinski Triangle.
        Use your mouse (scroll or touch) to zoom in!
      </p>
    </div>

    <!-- Canvas Area -->
    <div class="flex-1 p-4 md:p-10 bg-gray-300">
      <h1 class="w-full text-center md:text-left">Sierpinski Triangle</h1>
      <div class="mb-2 flex justify-center md:justify-start">
        <FractalButton 
          @click="zoomIn" 
          title="Zoom in to the fractal"
        >+</FractalButton>
        <FractalButton 
          @click="zoomOut" 
          title="Zoom out of to the fractal"
        >-</FractalButton>
      </div>
      <div class="w-full h-[50vh] md:h-[700px]" ref="drawing"></div>
    </div>
  </div>
</template>

<script>
import '@/assets/fractals.css'
import { SVG } from '@svgdotjs/svg.js'
import '@svgdotjs/svg.panzoom.js'
import ColorPicker from '@/components/common/ColorPicker.vue'
import IterationSlider from '@/components/common/IterationSlider.vue'
import FractalButton from '@/components/common/FractalButton.vue'

export default {
  name: 'SierpinskiTriangle',
  components: {
    ColorPicker,
    IterationSlider,
    FractalButton,
  },
  data() {
    return {
      draw: null,
      depth: 1,
      color: '#000000',
      currentZoomIn: 0
    }
  },
  mounted() {
    this.initializeCanvas()
  },
  methods: {
    initializeCanvas() {
      this.draw = SVG()
          .id('fractal-sierpinski-triangle')
        .addTo(this.$refs.drawing)
        .size('100%', '100%')
        .viewbox('0 0 1000 1000')
      this.zoom()
    },
    drawFractal() {
      this.draw.clear()
      if (this.depth > 11) {
        alert('Sorry the depth is too high and would crash your browser.')
      } else {
        this.drawTriangle(100, 50, 800, this.depth, 0.5)
      }
      this.zoom()
    },
    drawTriangle(x, y, size, depth, strokeWidth) {
      if (depth === 0) {
        this.draw
          .polygon([[x, y], [x + size / 2, y + size * Math.sqrt(3) / 2], [x + size, y]])
          .fill('none')
          .stroke({ width: strokeWidth, color: this.color })
      } else {
        const newSize = size / 2
        const newStrokeWidth = 1 / depth
        this.drawTriangle(x, y, newSize, depth - 1, newStrokeWidth)
        this.drawTriangle(x + newSize, y, newSize, depth - 1, newStrokeWidth)
        this.drawTriangle(
          x + newSize / 2,
          y + newSize * Math.sqrt(3) / 2,
          newSize,
          depth - 1,
          newStrokeWidth
        )
      }
    },
    zoom() {
      const options = { zoomMin: 0.5, zoomMax: 100 }
      this.draw.panZoom(options)
    },
    zoomIn() {
      this.currentZoomIn += 0.5
      this.draw.panZoom().zoom(this.currentZoomIn)
    },
    zoomOut() {
      if (this.currentZoomIn > 1) {
        this.currentZoomIn -= 0.5
        this.draw.panZoom().zoom(this.currentZoomIn)
      }
      if (this.currentZoomIn === 1) {
        this.currentZoomIn = 0.5
        this.draw.panZoom().zoom(this.currentZoomIn)
      }
    },
    resetCanvas() {
      this.draw.clear()
      this.depth = 1
    }
  },
  watch: {
    depth() {
      this.drawFractal()
    },
    color() {
      this.drawFractal()
    }
  }
}
</script>

<style scoped>

</style> 