<template>
    <div id="customers" class="mt-5 lg:mt-0 text-white">
        <h4 class="font-bold tracking-wider block mb-5 mt-10 w-full">I have worked with the following clients</h4>
        <div class="customer-logos grid grid-flow-row lg:grid-cols-4 grid-cols-2 grid-rows-2 gap-8">
            <div class="flex items-center justify-center">
                <a href="https://www.printweb.de/" title="Digital Druckerei Frankfurt Colour Connection GmbH" class="flex items-center justify-center w-full">
                    <img alt="Logo Colour Connection GmbH" :src="'/CC.png'" class="max-w-[150px] object-contain">
                </a>
            </div>
            <div class="flex items-center justify-center">
                <a href="https://www.kfw.de/kfw.de.html" title="KfW Bank" class="flex items-center justify-center w-full">
                    <img alt="Logo KfW Bank" :src="'/KFW.png'" class="max-w-[150px] object-contain">
                </a>
            </div>
          <div class="flex items-center justify-center">
            <img alt="Logo ZDF" :src="'/ZDF.png'" class="max-w-[150px] object-contain">
          </div>
          <div class="flex items-center justify-center">
            <img alt="Logo R+V Versicherung" :src="'/logo_ruv_weiss.svg'" class="max-w-[95px] object-contain">
          </div>
            <div class="flex items-center justify-center">
                <a href="https://bd-a.de" title="Big Data Analysis GmbH" class="flex items-center justify-center w-full">
                    <img alt="Logo Big Data Analysis GmbH" :src="'/BDA.png'" class="max-w-[150px] object-contain">
                </a>
            </div>
          <div class="flex items-center justify-center">
            <a href="https://www.thieleklose.de" title="Big Data Analysis GmbH" class="flex items-center justify-center w-full">
              <img alt="Logo Thiele und Klose GmbH" :src="'/thiele-und-klose-logo.svg'" class="max-w-[150px] object-contain">
            </a>
          </div>
            <div class="flex items-center justify-center">
                <img alt="Logo Wirtschaftsministerium Rheinland-Pfalz" :src="'/RLP.png'" class="max-w-[150px] object-contain">
            </div>
            <div class="flex items-center justify-center">
                <img alt="Logo Betty Blocks" :src="'/BB.png'" class="max-w-[150px] object-contain">
            </div>
            <div class="flex items-center justify-center">
                <img alt="Logo Stadt Mainz" :src="'/MZ.png'" class="max-w-[150px] object-contain">
            </div>
            <div class="flex items-center justify-center">
                <img alt="Logo Anyblock Analytics GmbH" :src="'/AA.png'" class="max-w-[150px] object-contain">
            </div>
            <div class="flex items-center justify-center">
                <img alt="Logo brainbot technologies AG" :src="'/BBot.png'" class="max-w-[150px] object-contain">
            </div>
            <div class="flex items-center justify-center">
                <img alt="Logo innoblock" :src="'/IB.png'" class="max-w-[150px] object-contain">
            </div>
            <div class="flex items-center justify-center">
                <img alt="Logo la-concept GmbH" :src="'/LA.png'" class="max-w-[150px] object-contain">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Customers"
    }
</script>

<style scoped>
.customer-logos img {
    height: 90px;
    width: auto;
    filter: grayscale(100%);
    opacity: 0.7;
    transition: all 0.3s ease;
}

.customer-logos img:hover {
    filter: grayscale(0%);
    opacity: 1;
}
</style>
