<template>
  <footer class="bg-black text-white py-8 mt-auto">
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-5">
      <!-- Logo Column -->
      <div class="flex items-center p-5 text-left">
        <img src="@/assets/nhan-initialen-logo-weiss.png" alt="Nhan Vu Logo" class="h-32 p-5">
        <p class="tagline">
          X.ecute.<br>
          X.ercise.<br>
          X.cell.
        </p>
      </div>
      <div></div>
      <div></div>
      <!-- Additional Text Column -->
      <div class="items-center text-left p-5">
        <div id="mission" class="mb-10">
          <h4 class="font-bold mb-2 block">my mission</h4>
          <p>
            I bridge vision & execution <br> and turn business ideas to reality.
          </p>
        </div>
        <Imprint></Imprint>
      </div>
    </div>
  </footer>
</template>

<script>
import Imprint from "@/components/Imprint.vue";

export default {
  name: 'Footer',
  components: {Imprint},
}
</script> 