<template>
  <div class="mt-4">
    <div id="google-calendar-button"></div>
  </div>
</template>

<script>
export default {
  name: 'ConsultationButton',
  mounted() {
    const script = document.createElement('script');
    script.src = "https://calendar.google.com/calendar/scheduling-button-script.js";
    script.async = true;
    script.onload = () => {
      const target = document.getElementById('google-calendar-button');
      window.calendar.schedulingButton.load({
        url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1BR167Rj-TuhTPKdvGUD7WAWxpVfjRkYiIEYpCAYG6TpOskOlvYDZGUhIRd0eC_k5QB8lozuOw?gv=true',
        color: '#4fd1c5',
        label: "Book a free, non-binding consultation",
        target,
      });

      const observer = new MutationObserver(() => {
        const calendarDiv = document.getElementById('google-calendar-button');
        const button = calendarDiv?.nextElementSibling;
        if (button instanceof HTMLButtonElement) {
          button.classList.add('btn', 'btn-white', 'btn-large', 'mr-2', 'hover:bg-teal-400');
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    };

    document.head.appendChild(script);
  }
}
</script> 