<template>
  <div class="references">
    <section class="hero">
      <div class="hero-content">
        <h1>References & Success Stories</h1>
        <p class="text-xl md:text-2xl">
          Successful innovation arises from the right combination of strategy,
          technology, and teamwork. Here are some examples of how I have helped clients achieve their goals.
        </p>
      </div>
    </section>

    <section class="references-grid">
      <!-- Strategy Development & Tech Integration -->
      <div class="reference-card">
        <img :src="'/tech-integration.png'" alt="Tech Integration Reference" />
        <div class="reference-content">
          <h3>Strategy & Tech Integration</h3>
          <h4>Colour Connection GmbH, Frankfurt</h4>
          <p>
            Development and implementation of a scalable multi-shop integration
            for a digital printing provider.
          </p>
          <ul class="list-disc pl-5 mt-4 text-left">
            <li>4000+ automated orders</li>
            <li>30 minutes time savings per order</li>
            <li>Successful integration of 10+ partner shops</li>
            <li>Implementation with Laravel & FilamentPHP</li>
          </ul>
          <div class="testimonial">
            <p class="italic">
              "Working with Nhan is extremely positive, characterized by agility,
              thoughtful planning, and detailed result orientation."
            </p>
            <p class="font-bold mt-2">— Jens Stork, CTO Colour Connection GmbH</p>
          </div>
        </div>
      </div>

      <!-- Tech Education & Team Building -->
      <div class="reference-card">
        <img :src="'/tech-education.png'" alt="Tech Education Reference" />
        <div class="reference-content">
          <h3>Strategy & Team Building</h3>
          <h4>Big Data Analysis GmbH, Mainz</h4>
          <p>
            Strategic repositioning of the business model and offering.
          </p>
          <ul class="list-disc pl-5 mt-4">
            <li>Successful placement of the COO</li>
            <li>Implementation of best practices</li>
            <li>Market and target group analysis</li>
            <li>Strategic management consulting</li>
          </ul>
          <div class="testimonial">
            <p class="italic">
              "The long-term cooperation with Nhan since 2017 has proven to be
              extremely valuable for the BD-A GmbH. With him at our side, we can
              say with full confidence that we have an extremely reliable partner."
            </p>
            <p class="font-bold mt-2">— Klaus Schlitt, CEO & Founder, BD-A GmbH</p>
          </div>
        </div>
      </div>

      <!-- Fast Prototyping & AI Integration -->
      <div class="reference-card">
        <img :src="'/fast-prototyping.png'" alt="Fast Prototyping Reference" />
        <div class="reference-content">
          <h3>Fast Prototyping & AI Integration</h3>
          <h4>innoBlock UG, Mainz</h4>
          <p>
            Development and implementation of an enterprise blockchain solution
            with rapid prototyping methodology.
          </p>
          <ul class="list-disc pl-5 mt-4">
            <li>MVP development with Test Driven Development</li>
            <li>Enterprise blockchain with Hyperledger Fabric</li>
            <li>Track & Trace web application</li>
            <li>Agile coaching of the development team</li>
          </ul>
        </div>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="cta mx-auto border-2 border-teal-400">
      <h2 class="text-teal-400">Ready for the Next Step?</h2>
      <p class="w-1/2 mx-auto">
        Let's discuss how I can also lead your project to success.
        From strategy development to technical implementation – I support you
        at every step.
      </p>
      <ConsultationButton />
    </section>
  </div>
</template>

<script>
import ConsultationButton from "@/components/ConsultationButton.vue";

export default {
  name: 'References' ,
  components: {ConsultationButton}
}
</script>

<style scoped>
.references {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #e0e0e0;
}

.references-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.reference-card {
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
}

.reference-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.reference-content h3 {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.reference-content h4 {
  color: #ffffff;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.testimonial {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

@media (max-width: 768px) {
  .references-grid {
    grid-template-columns: 1fr;
  }
}

.reference-content {
  text-align: left;
}
</style> 