<template>
  <div id="fractal-koch" class="fractal-content flex flex-col md:flex-row">
    <div class="w-full md:w-64 border-b md:border-e md:border-b-0 bg-white text-black">
      <div class="px-4 py-6 text-left">
        <ul class="mt-6 space-y-1">
          <li class="flex flex-col items-center md:items-start">
            <IterationSlider
                v-model="depth"
                :max="7"
                :min="0"
                :step="1"
            />

            <ColorPicker
                v-model="color"
            />

            <button
                class="w-full md:w-2/3 block mb-2"
                @click="drawFractal"
                type="button"
                title="Draw the fractal"
            >
              Draw
            </button>
            <button
                class="w-full md:w-2/3 block"
                @click="resetCanvas"
                type="button"
                title="Reset the canvas"
            >
              Reset
            </button>
          </li>
        </ul>
      </div>
      <p class="p-4 text-xs text-gray-800 text-left max-w-prose mx-auto md:mx-0">
        The Koch snowflake (or Koch star) is a mathematical curve and one of the earliest fractal curves to have
        been described. It starts with an equilateral triangle, and recursively alters each line segment by dividing
        it into thirds and replacing the middle segment with an equilateral bump.
      </p>
    </div>
    <div class="flex-1 p-4 md:p-10 bg-gray-300">
      <h1 class="w-full text-center md:text-left">Koch Snowflake</h1>
      <div class="mb-2 flex justify-center md:justify-start">
        <FractalButton
            @click="zoomIn"
            title="Zoom in to the fractal"
        >+
        </FractalButton>
        <FractalButton
            @click="zoomOut"
            title="Zoom out of to the fractal"
        >-
        </FractalButton>
      </div>
      <div class="w-full h-[50vh] md:h-[700px]" ref="drawing"></div>
    </div>
  </div>
</template>

<script>
import '@/assets/fractals.css'
import {SVG} from '@svgdotjs/svg.js'
import '@svgdotjs/svg.panzoom.js'
import ColorPicker from '@/components/common/ColorPicker.vue'
import IterationSlider from '@/components/common/IterationSlider.vue'
import FractalButton from '@/components/common/FractalButton.vue'

export default {
  name: 'KochSnowflake',
  components: {
    ColorPicker,
    IterationSlider,
    FractalButton,
  },
  data() {
    return {
      draw: null,
      depth: 0,
      color: '#000000',
      currentZoomIn: 0
    }
  },
  mounted() {
    this.initializeCanvas()
  },
  methods: {
    initializeCanvas() {
      let CANVAS_HEIGHT = "100%";
      let CANVAS_WIDTH = "100%";
      this.draw = SVG()
          .id('fractal-koch-snowflake')
          .addTo(this.$refs.drawing)
          .size(CANVAS_WIDTH, CANVAS_HEIGHT)
          .viewbox('0 0 1000 720')
      this.zoom()
    },
    drawFractal() {
      const SvgRootElement = this.$refs.drawing;
      this.draw.clear()
      if (this.depth > 7) {
        alert('Sorry the depth is too high and would crash your browser.')
      } else {
        const points = this.generateSnowflake(this.depth)
        const snowflakePolygon = this.generatePolygonSVG(points, 500)
        let actualHeight = SvgRootElement.getBoundingClientRect().height;
        let yPos = actualHeight / 2;
        let xPos = snowflakePolygon.width();
        snowflakePolygon.center(xPos, yPos)
      }
      this.zoom()
    },
    kochSnowflake(iterations) {
      let points = [[0, 0], [0.5, Math.sqrt(3) / 2], [1, 0]]
      for (let i = 0; i < iterations; i++) {
        points = this.iterate(points)
      }
      return points
    },
    iterate(points) {
      const newPoints = []
      for (let i = 0; i < points.length - 1; i++) {
        const p1 = points[i]
        const p2 = points[i + 1]
        const dx = p2[0] - p1[0]
        const dy = p2[1] - p1[1]
        const q = [p1[0] + dx / 3, p1[1] + dy / 3]
        const r = [p1[0] + dx / 2 - dy * Math.sqrt(3) / 6, p1[1] + dy / 2 + dx * Math.sqrt(3) / 6]
        const s = [p1[0] + 2 * dx / 3, p1[1] + 2 * dy / 3]
        newPoints.push(p1, q, r, s)
      }
      newPoints.push(points[points.length - 1])
      return newPoints
    },
    rotatePoint(p, angle, center) {
      const s = Math.sin(angle)
      const c = Math.cos(angle)
      const px = p[0] - center[0]
      const py = p[1] - center[1]
      const xnew = px * c - py * s + center[0]
      const ynew = px * s + py * c + center[1]
      return [xnew, ynew]
    },
    generateSnowflake(iterations) {
      const snowflake = []
      const side = this.kochSnowflake(iterations)

      // Add the first side
      snowflake.push(...side)

      // Add the second side
      const rotatedSide1 = side.map(p => this.rotatePoint([...p], 2 * Math.PI / 3, [0.5, Math.sqrt(3) / 6]))
      snowflake.push(...rotatedSide1)

      // Add the third side
      const rotatedSide2 = side.map(p => this.rotatePoint([...p], -2 * Math.PI / 3, [0.5, Math.sqrt(3) / 6]))
      snowflake.push(...rotatedSide2)

      return snowflake
    },
    generatePolygonSVG(points, scaleFactor) {
      const scaledPoints = points.map(point => [point[0] * scaleFactor, point[1] * scaleFactor])
      const polygon = this.draw.polygon(scaledPoints).addClass('koch_snowflake')
      const strokeThickness = this.depth > 0 ? 1 / this.depth : 1
      polygon.fill('none').stroke({width: strokeThickness, color: this.color})
      return polygon
    },
    zoom() {
      const options = {zoomMin: 0.5, zoomMax: 100}
      this.draw.panZoom(options)
    },
    zoomIn() {
      this.currentZoomIn += 0.5
      this.draw.panZoom().zoom(this.currentZoomIn)
    },
    zoomOut() {
      if (this.currentZoomIn > 1) {
        this.currentZoomIn -= 0.5
        this.draw.panZoom().zoom(this.currentZoomIn)
      }
      if (this.currentZoomIn === 1) {
        this.currentZoomIn = 0.5
        this.draw.panZoom().zoom(this.currentZoomIn)
      }
    },
    resetCanvas() {
      this.draw.clear()
      this.depth = 0
    }
  },
  watch: {
    depth() {
      this.drawFractal()
    },
    color() {
      this.drawFractal()
    }
  }
}
</script> 