import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '@/components/LandingPage.vue'
import References from '@/components/References.vue'
import SierpinskiTriangle from '@/components/fractals/SierpinskiTriangle.vue'
import KochSnowflake from '@/components/fractals/KochSnowflake.vue'
import DragonCurve from '@/components/fractals/DragonCurve.vue'
import FractalIntroduction from '@/components/fractals/FractalIntroduction.vue'
import LifeTime from "@/components/generative-ai/LifeTime.vue";
import DesignIntroduction from "@/components/generative-ai/DesignIntroduction.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage
  },
  {
    path: '/references',
    name: 'References',
    component: References
  },
  {
    path: '/experiments',
    name: 'X.periments',
    children: [
      {
        path: 'design',
        name: 'Design',
        component: DesignIntroduction
      },
      {
        path: 'fractals',
        name: 'Fractals',
        component: FractalIntroduction,
        children: [
          {
            path: 'sierpinski',
            name: 'Sierpinski Triangle',
            component: SierpinskiTriangle
          },
          {
            path: 'koch',
            name: 'Koch Snowflake',
            component: KochSnowflake
          },
          {
            path: 'dragon',
            name: 'Dragon Curve',
            component: DragonCurve
          }
        ]
      },
    ]
  },
  {
    path: '/lifetime',
    name: 'Life Time',
    component: LifeTime
  }
]

const router = createRouter({
  linkActiveClass: 'btn-active',
  history: createWebHistory(),
  routes
})

export default router 