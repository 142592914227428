<template>
  <div class="landing-page">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <h1>Where Ideas Meet Execution</h1>
        <p class="text-xl md:text-2xl">
          Progress is the only way forward—for you, your team, and your ideas. <br>
          Innovation thrives at the intersection of perspectives, where visions transform into actionable plans and small steps grow into a bold, purposeful journey.
        </p>
      </div>
    </section>

    <!-- About Section -->
    <section class="about bg-black">
      <div class="about-content flex flex-col md:flex-row items-center justify-center gap-8 p-4 md:p-10">
        <div class="w-[250px] md:w-[300px] aspect-square rounded-full overflow-hidden flex-shrink-0">
          <img
            src="@/assets/nhan-profile.jpg"
            alt="Nhan Vu"
            class="w-full h-full object-cover object-top"
          />
        </div>
        <div class="about-text max-w-2xl text-center md:text-left">
           <p class="p-5">
             I am Nhân, your experienced partner who brings your visions to life. <br> With over two decades of experience in technology and business development, I help you turn your ideas into clear, efficient, and successful outcomes.
           </p>
          <p class="p-5">
             I connect creativity, technology, and business. Together, we transform complex challenges into actionable solutions that drive growth and innovation.

             My current focus is on helping businesses like yours integrate Generative AI effectively and develop scalable, future-proof technologies.
          </p>
          <p class="p-5">
            Let’s turn your ideas into action—through strategic partnerships, personalized consulting, or joint innovation projects.
          </p>
          <h3 class="text-4xl">I bridge vision & execution <br>
            and turn business ideas to reality.
          </h3>
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section class="services">
      <h2>What I Can Do For You</h2>
      <div class="services-grid">
        <div class="service-card text-left">
          <img
              :src="'idee-schritt.png'"
              alt="From Idea to First Step"
          />
          <h3>From Idea to First Step</h3>
          <p>
            Every big journey starts with a clear goal - and the first step. Together,
            we'll bring your vision to life.
          </p>
        </div>

        <div class="service-card text-left">
          <img
              :src="'bewegung-wachstum.png'"
              alt="Movement that Fuels Growth"
          />
          <h3>Movement that Fuels Growth</h3>
          <p>
            Innovation is like training: It requires a good structure,
            repeatable processes, and the willingness to always get better.
          </p>
        </div>

        <div class="service-card text-left">
          <img
              :src="'bruecke-hindernis.png'"
              alt="Overcoming Obstacles"
          />
          <h3>Overcoming Obstacles, Developing Dynamics</h3>
          <p>
            I build bridges between creativity, technology, and business, so that
            standing still becomes dynamic again.
          </p>
        </div>
      </div>
    </section>

    <section class="bg-black">
      <h2 class="text-6xl">3X</h2>
      <p>I believe that business growth is based on three key practices.</p>
      <img src="@/assets/nhan-initialen-logo-weiss.png" alt="Nhan Vu Logo" class="h-64 p-5 mx-auto">
      <div class="hero container mx-auto grid grid-cols-1 md:grid-cols-3 gap-5">
        <div>
          <span class="text-3xl tagline">X.ecute</span>
          <p>Execution is movement. <br> Movement is progress. <br>Progress is growth.</p>
        </div>
        <div>
          <span class="text-3xl tagline">X.ercise</span>
          <p>Exercise is learning. <br> Learning is improvement. <br> Improvement is growth.</p>
        </div>
        <div>
          <span class="text-3xl tagline">X.cell</span>
          <p>Excellence through repetition. Repetition is performance. Performance is growth.</p>
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section class="services">
      <h2>How We Can Work Together</h2>
      <div class="services-grid">
        <div class="service-card text-left">
          <img :src="'strategie.png'" alt="Strategy Development" />
          <h3>Strategy Development</h3>
          <p>
            Together, we'll develop a clear strategy that drives your vision forward.
            Whether it's about aligning a corporate venture or focusing a startup -
            I'll help you set the right priorities and define concrete actions.
          </p>
          <ul class="list-disc pl-5 mt-4">
            <li>A roadmap with clear milestones</li>
            <li>Practical approaches to overcome obstacles</li>
            <li>Strategic bridges between creativity, technology, and business</li>
          </ul>
        </div>

        <div class="service-card text-left">
          <img :src="'tech-education.png'" alt="Tech Education" />
          <h3>Tech Education</h3>
          <p>
            I offer clear and practice-oriented training in technologies that drive
            innovation. Whether Generative AI, Web3, or the Laravel Stack - my goal
            is to bring your team up to speed.
          </p>
          <ul class="list-disc pl-5 mt-4">
            <li>Generative AI: Creative and technical processes</li>
            <li>Web3: Fundamentals of decentralized technologies</li>
            <li>Laravel Stack: Best practices for scalable web development</li>
          </ul>
        </div>

        <div class="service-card text-left">
          <img :src="'tech-integration.png'" alt="Tech Integration" />
          <h3>Tech Integration (Generative AI)</h3>
          <p>
            Innovation is only as good as its implementation. I help you integrate
            Generative AI efficiently into your existing systems.
          </p>
          <ul class="list-disc pl-5 mt-4">
            <li>Analysis of your processes for meaningful KI use</li>
            <li>Technical implementation of Generative-AI-Tools</li>
            <li>Support during introduction and optimization</li>
          </ul>
        </div>

        <div class="service-card text-left">
          <img :src="'team-aufbau.png'" alt="Team Building and Support" />
          <h3>Team Building and Support</h3>
          <p>
            A strong team is the foundation for every successful project. I support
            you in building and developing interdisciplinary teams.
          </p>
          <ul class="list-disc pl-5 mt-4">
            <li>Recruitment of the right talents</li>
            <li>Promotion of interdisciplinary cooperation</li>
            <li>Continuous coaching and mentoring</li>
          </ul>
        </div>

        <div class="service-card text-left">
          <img :src="'fast-prototyping.png'" alt="Fast Prototyping" />
          <h3>Fast Prototyping with Generative AI</h3>
          <p>
            With my experience in Generative AI and over 10 years of coding practice,
            I create prototypes that are immediately testable.
          </p>
          <ul class="list-disc pl-5 mt-4">
            <li>Quick MVPs for initial market validation</li>
            <li>Use of Generative AI for quick development</li>
            <li>Technically robust solutions for further growth</li>
          </ul>
        </div>
      </div>
    </section>

    <Testimonials>

    </Testimonials>

    <!-- Experience Section -->
    <section class="experience">
      <h2>Why I'm the Right Person for You</h2>
      <div class="experience-grid">
        <div class="experience-item">
          <h4>1. Entrepreneurial</h4>
          <p>As a serial entrepreneur, I've learned how to build a business from first steps.</p>
        </div>
        <div class="experience-item">
          <h4>2. Strategic</h4>
          <p>As a co-builder of a venture studio, I know how to steer movement into the right direction.</p>
        </div>
        <div class="experience-item">
          <h4>3. Creative</h4>
          <p>As a media designer, I understand how to move and inspire target groups.</p>
        </div>
        <div class="experience-item">
          <h4>4. Technical</h4>
          <p>As a programmer, I know what's needed to turn an idea into a functional product.</p>
        </div>
        <div class="experience-item">
          <h4>5. Analytically Curious</h4>
          <p>My ability to penetrate topics down to their fundamentals allows me to understand core problems.</p>
        </div>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="cta bg-black border-2 border-teal-400">
      <h2 class="text-teal-400">Let's Bring Your Ideas to Life</h2>
      <p class="sm:w-10/2 mx-auto w-10/12">
        Great ideas deserve action, not stagnation. <br>Book a free online consultation, and let’s take the first steps together <br>— clearly, efficiently, and with the focus needed to turn your vision into success.
      </p>
      <ConsultationButton />
    </section>

    <section class="client-section">
      <Customers></Customers>
    </section>
  </div>
</template>

<script>
import Testimonials from './Testimonials.vue'
import ConsultationButton from './ConsultationButton.vue'
import Customers from "@/components/Customers.vue";

export default {
  name: 'LandingPage',
  components: {
    Customers,
    Testimonials,
    ConsultationButton
  }
}
</script>

<style scoped>
.landing-page {
  padding: 2rem;
  color: #e0e0e0;
}

.hero {
  text-align: center;
  padding: 4rem 0;
}

p {
  @apply font-medium;
}

.about {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 4rem 0;
}


.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.service-card {
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
}

.service-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.service-card h3 {
  color: #ffffff;
}

.experience-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.experience-item {
  padding: 1.5rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.experience-item h4 {
  color: #ffffff;
}

@media (max-width: 768px) {
  .about {
    flex-direction: column;
    text-align: center;
  }

  .services-grid {
    grid-template-columns: 1fr;
  }

  .experience-grid {
    grid-template-columns: 1fr;
  }
}
</style>