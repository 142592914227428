<template>
  <div class="fractal-page mx-auto pt-8 text-white">
    <section class="hero p-10">
      <div class="hero-content">
        <h1 class="font-bold mb-8 text-center w-full">Experiments with Fractals</h1>
        <div>
          <img src="@/assets/dragon.svg" alt="Dragon"/>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <!-- Introduction Section -->
          <div class="mb-12 md:mb-0">
            <h2 class="mb-4">What are Fractals</h2>
            <p class="mb-4">
              Fractals are extraordinary mathematical patterns that repeat themselves at different scales.
              They are infinite patterns that are self-similar across different scales, created by repeating
              a simple process over and over in an ongoing feedback loop.
            </p>
          </div>

          <!-- Personal Connection Section -->
          <div>
            <h2 class="mb-4">Why I Love Fractals</h2>
            <p class="mb-4">
              I’m fascinated by fractals—the perfect fusion of math, art, and nature.
              Their intricate beauty, born from simple rules, inspires me as a developer and pattern enthusiast
              to create and share these mathematical wonders.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Interactive Visualizations -->
    <section class="p-20 bg-black">
      <h2 class="text-2xl font-semibold mb-4">My Interactive Visualizations</h2>
      <p class="mb-4">
        Explore my collection of interactive fractal visualizations. Each implementation allows you
        to interact with the fractal patterns and observe their fascinating properties.
      </p>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <router-link
            to="/experiments/fractals/sierpinski"
            class="btn btn-large block border-2 rounded-lg border-white hover:btn-active">
          <h3 class="text-xl">Sierpinski Triangle</h3>
        </router-link>
        <router-link
            to="/experiments/fractals/koch"
            class="btn btn-large block border-2 rounded-lg border-white hover:btn-active">
          <h3 class="text-xl">Koch Snowflake</h3>
        </router-link>

        <router-link
            to="/experiments/fractals/dragon"
            class="btn btn-large block border-2 rounded-lg border-white hover:btn-active">
          <h3 class="text-xl">Dragon Curve</h3>
        </router-link>
      </div>
      <button
          v-if="activeSvgId" 
          class="btn btn-large btn-white mt-5"
          @click="downloadLifeTimePdf"
      >
        Download as PDF
      </button>
    </section>
    <router-view></router-view>

    <!-- Applications Section -->
    <section class="bg-cover p-10 bg-center bg-no-repeat bg-blend-overlay bg-gray-900/75"
             style="background-image: url('/mandelbrot.webp'); height: 400px">
      <!-- Content with relative positioning to appear above the overlay -->
      <div class="relative z-10">
        <h2>Fractals' Practical Applications</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div class="mb-3">
            <h3>In Nature</h3>
            <p>Found in coastlines, mountains, clouds, and plant structures like ferns and cauliflowers.</p>
          </div>
          <div class="mb-3">
            <h3>In Science</h3>
            <p>Used in antenna design, medical imaging, and studying complex systems like weather patterns.</p>
          </div>
          <div class="mb-3">
            <h3>In Business</h3>
            <p>Applied in market analysis, risk assessment, and network design.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="cta p-10 mx-auto">
      <div class="border-2 p-10 border-teal-400">
        <h2 class="text-teal-400 text-3xl mb-4">Have an Experiment in Mind?</h2>
        <p class="w-3/4 mx-auto">
          Whether you have an idea for a digital product or need guidance on bringing your vision 
          to life, let's explore the possibilities together. Book a free consultation to discuss 
          your experiments. The first step that makes your idea tangible?
        </p>
        <ConsultationButton/>
      </div>
    </section>
  </div>
</template>

<script>
import ConsultationButton from "@/components/ConsultationButton.vue";
import {exportSvgToPdf} from "@/utils/pdfExport";

export default {
  name: 'FractalIntroduction',
  components: {
    ConsultationButton
  },
  computed: {
    activeSvgId() {
      switch (this.$route.path) {
        case '/experiments/fractals/sierpinski':
          return 'fractal-sierpinski-triangle';
        case '/experiments/fractals/koch':
          return 'fractal-koch-snowflake';
        case '/experiments/fractals/dragon':
          return 'fractal-dragon-curve';
        default:
          return null;
      }
    }
  },
  methods: {
    async downloadLifeTimePdf() {
      if (!this.activeSvgId) {
        alert('No active fractal selected.');
        return;
      }

      const success = await exportSvgToPdf({
        svgId: this.activeSvgId,
        title: '',
        filename: `${this.activeSvgId}.pdf`
      });

      if (!success) {
        alert('Sorry, there was an error generating the PDF.');
      }
    },
  }
}
</script> 