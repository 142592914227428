<template>
  <div>
    <p>Iteration: <output>{{ modelValue }}</output></p>
    <input
      class="w-2/3 mb-2 bg-gray-200"
      type="range"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :max="max"
      :min="min"
      :step="step"
      list="markers"
    />
    <datalist id="markers" class="mb-5">
      <option 
        v-for="n in max" 
        :key="n" 
        :value="n" 
        :label="`${n} iterations`" 
        class="text-xs" 
      />
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'IterationSlider',
  props: {
    modelValue: {
      type: [Number, String],
      required: true
    },
    max: {
      type: Number,
      default: 11
    },
    min: {
      type: Number,
      default: 1
    },
    step: {
      type: Number,
      default: 1
    }
  }
}
</script> 