<template>
  <header id="header" class="bg-black md:static sticky top-0 p-3 z-50">
    <nav class="flex items-center justify-between bg-black">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <router-link to="/" title="Home">
          <img src="@/assets/nhan-initialen-logo-weiss.png" alt="Nhan Vu Logo" class="h-20 w-20 mr-2"/>
        </router-link>
      </div>
      <div class="nav-items hidden lg:flex items-center justify-end flex-grow">
        <router-link
            v-for="item in mainNavItems"
            :key="item.name"
            :to="item.path"
            class="btn btn-white btn-sm mr-2 hover:bg-teal-400 active:bg-teal-400"
            :title="item.name"
        >
          {{ item.name }}
        </router-link>
        
        <!-- Labs dropdown -->
        <div class="nav-items relative group">
          <button
              class="btn btn-white btn-sm mr-2 hover:bg-teal-400"
              @click="toggleLabsMenu"
          >
            X.periments
          </button>
          <div
              v-show="showLabsMenu"
              class="nav-items absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50 text-left"
          >
            <div v-for="category in labsItems" :key="category.name">
              <router-link
                  :to="`/experiments/${category.path}`"
                  class="block px-4 py-2 text-sm text-gray-700 font-semibold hover:bg-gray-100"
              >
                {{ category.name }}
              </router-link>
              <router-link
                  v-for="item in category.children"
                  :key="item.name"
                  :to="`/experiments/${category.path}/${item.path}`"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 pl-8"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
        
        <!-- Contact buttons -->
        <div class="flex items-center">
          <a class="btn btn-white btn-sm mr-2 hover:bg-teal-400"
             id="btn-send-email"
             title="Nhan Vu eine E-Mail schicken"
             href="mailto:nhan@nhanvu.de">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path
                  d="M4 4h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 01-.9 0L4 9.62V18h16V9.62z"/>
            </svg>
          </a>
          <a class="btn btn-white btn-sm hover:bg-teal-400 mr-2"
             id="btn-call-phone"
             title="Nhan Vu auf dem Handy anrufen +4915732742217"
             href="tel:+4915732742217">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-6">
              <path
                  d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4zm7.43 5.7a1 1 0 1 1-1.42-1.4L18.6 4H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V5.41l-4.3 4.3z"/>
            </svg>
          </a>
        </div>
      </div>

      <!-- Burger menu button -->
      <button @click="toggleMobileMenu" class="lg:hidden text-white hover:text-teal-400">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <title>Menu</title>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </nav>

    <div class="nav-items flex items-center flex-wrap justify-between">
      <!-- Desktop navigation -->

      <!-- Mobile navigation overlay -->
      <nav v-show="showMobileMenu"
           class="fixed inset-0 bg-black bg-opacity-50 md:hidden z-40"
           @click="toggleMobileMenu">
        <div class="absolute right-0 top-0 h-full w-64 bg-black p-4" @click.stop>
          <!-- Close button positioned to top left -->
          <button @click="toggleMobileMenu" class="absolute top-4 left-4 text-white hover:text-teal-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          
          <div class="flex flex-col space-y-4 mt-12">
            <router-link
              v-for="item in mainNavItems"
              :key="item.name"
              :to="item.path"
              class="btn btn-white btn-sm hover:bg-teal-400"
              :title="item.name"
              @click="toggleMobileMenu"
            >
              {{ item.name }}
            </router-link>

            <!-- Mobile X.periments dropdown -->
            <div class="nav-items space-y-2">
              <button
                class="btn btn-white btn-sm w-full hover:bg-teal-400"
                @click="toggleLabsMenu"
              >
                X.periments
              </button>
              <div v-show="showLabsMenu" class="pl-4 space-y-2">
                <div v-for="category in labsItems" :key="category.name" class="space-y-2">
                  <router-link
                    :to="`/experiments/${category.path}`"
                    class="block text-white hover:text-teal-400"
                    @click="toggleMobileMenu"
                  >
                    {{ category.name }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <a class="btn btn-white btn-sm mr-2 hover:bg-teal-400"
               id="btn-send-email"
               title="Nhan Vu eine E-Mail schicken"
               href="mailto:nhan@nhanvu.de">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path
                    d="M4 4h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 01-.9 0L4 9.62V18h16V9.62z"/>
              </svg>
            </a>
            <a class="btn btn-white btn-sm hover:bg-teal-400 mr-2"
               id="btn-call-phone"
               title="Nhan Vu auf dem Handy anrufen +4915732742217"
               href="tel:+4915732742217">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-6">
                <path
                    d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4zm7.43 5.7a1 1 0 1 1-1.42-1.4L18.6 4H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V5.41l-4.3 4.3z"/>
              </svg>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      showMobileMenu: false,
      showLabsMenu: false,
      mainNavItems: [
        {path: '/', name: 'Home'},
        {path: '/references', name: 'References'},
      ],
      labsItems: [
        {
          name: 'Design',
          path: 'design',
        },
        {
          name: 'Fractals',
          path: 'fractals',
        }
      ]
    }
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu
      if (!this.showMobileMenu) {
        this.showLabsMenu = false
      }
    },
    toggleLabsMenu() {
      this.showLabsMenu = !this.showLabsMenu
    }
  },
  mounted() {
    // Close menu when clicking outside
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.group')) {
        this.showLabsMenu = false
      }
    })
  }
}
</script>

<style scoped>

.group:hover .absolute {
  display: block;
}
</style>
