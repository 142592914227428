<template>
  <div class="life-visualization max-w-7xl mx-auto w-full p-2 lg:p-5 flex flex-col items-center gap-5">
    <div class="controls w-full flex flex-col sm:flex-row gap-2 mb-5">
      <div class="inputs-wrapper flex w-full gap-2 flex-1">
        <input
            v-model="name"
            type="text"
            placeholder="Enter your name"
            :disabled="isAnimating"
            class="text-black flex-1 min-w-0 px-3 h-10 bg-white rounded"
        >
        <div class="flex-1 flex flex-col">
          <div class="relative flex-1">
            <div class="relative flex items-center">
              <input
                  v-model="birthdate"
                  type="date"
                  :max="currentDate"
                  :disabled="isAnimating"
                  class="text-black flex-1 min-w-0 px-3 h-10 bg-white rounded w-full"
                  placeholder="Enter your birth date"
                  @blur="validateBirthdate"
                  name="birthdate"
                  required
              >
              <svg id="calendar-icon" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-3 pointer-events-none visible lg:hidden" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </div>
          </div>
          <span v-if="birthdateError" class="text-red-500 text-sm mt-1">
            {{ birthdateError }}
          </span>
        </div>
      </div>
      <div class="buttons-wrapper flex w-full gap-2 flex-1">
        <button class="btn btn-sm lg:btn-large btn-border-active flex-1"
                @click="startVisualization"
                :disabled="!isValid || isAnimating"
        >
          Show my time
        </button>
        <button class="btn btn-sm lg:btn-large btn-border-active flex-1"
                @click="reset"
                :disabled="!hasStarted"
        >
          Reset
        </button>
      </div>
    </div>

    <div class="paper-container w-full max-w-5xl bg-white p-0 lg:p-10 shadow-sm mb-10">
      <svg
          ref="svgContainer"
          :viewBox="`0 0 ${svgWidth} ${svgHeight}`"
          class="life-grid"
          id="life-visualization-svg"
          :width="svgWidth"
          :height="svgHeight"
      >

        <!-- Year rectangles -->
        <g
            id="grid-container"
            :transform="`translate(${(svgWidth - gridWidth) / 2}, ${(svgHeight - gridHeight) / 2})`"
        >
        <!-- Age headline -->
        <text
            :x="svgWidth / 2"
            y="0"
            text-anchor="middle"
            class="text-3xl font-bold"
            :fill="'#262525'"
            style="font-family: 'Poiret One Regular', Arial, sans-serif; font-size: 30px;"
            dominant-baseline="middle"
        >
          <tspan v-if="age > 0 && age <= 90" dy="0">
            Your Time On Earth: {{ age }} {{ age === 1 ? 'year' : 'years' }}
          </tspan>
          <tspan v-else-if="age < 0" dy="0">
            Be happy! You are still to be born.
          </tspan>
          <tspan v-else-if="age > 90 && age <= 120" dy="0">
            {{ age }} years. Jesus! That's impressive.
          </tspan>
          <tspan v-else-if="age > 120 && age <= 500" dy="0">
            {{ age }} years. You surfed on th back of a Greenland shark 🦈!
          </tspan>
          <tspan v-else-if="age > 500 && age <= 1000" dy="0">
            {{ age }} years. 🤔 seriously?
          </tspan>
          <tspan v-else-if="age > 1000 && age <= 2000" dy="0">
            {{ age }} years. You have a 🦕 as a pet?
          </tspan>
          <tspan v-else-if="age > 2000" dy="0">
            {{ age }} years. You must know Moses!
          </tspan>
        </text>

          <g v-for="(year, index) in years" :key="index">
            <rect
                :x="getX(index)"
                :y="getY(index) + 50"
                :width="rectWidth"
                :height="rectHeight"
                :stroke="year.passed ? '#8e8e8e' : '#262525'"
                stroke-width="2"
                fill="none"
                :style="{ opacity: year.visible ? 1 : 0 }"
                class="year-rect"
            />
            <text
                :x="getX(index) + rectWidth/2"
                :y="getY(index) + 50 + rectHeight - (rectHeight/1.8)"
                text-anchor="middle"
                dominant-baseline="middle"
                :style="{ opacity: year.visible ? 1 : 0 }"
                :fill="year.passed ? '#8e8e8e' : '#262525'"
                class="font-bold"
                style="font-family: 'DM Sans Bold', Arial, sans-serif; font-size: 14px;"
            >
              {{ index + 1 }}
            </text>
            <text
                :x="getX(index) + rectWidth/2"
                :y="getY(index) + 50 + rectHeight - 15"
                text-anchor="middle"
                dominant-baseline="middle"
                :style="{ opacity: year.visible ? 1 : 0 }"
                :fill="year.passed ? '#8e8e8e' : '#262525'"
                class="text-xs"
                style="font-family: 'DM Sans Regular', Arial, sans-serif; font-size: 10px;"
            >
              {{ new Date().getFullYear() - age + index }}
            </text>
          </g>
        </g>
        <g v-if="isComplete" id="final-message">
          <text
                :x="textXPosition"
                :y="textYPosition"
                text-anchor="middle"
                :fill="'#262525'"
                style="font-family: 'Poiret One Regular', Arial, sans-serif; font-size: 20px;"
                dominant-baseline="middle"
          >
            <tspan dy="0">
              Give meaning to your life, {{ name }}.
            </tspan>
          </text>
          <text
                :x="textXPosition"
                :y="textYPosition + 30"
                text-anchor="middle"
                :fill="'#262525'"
                style="font-family: 'Poiret One Regular', Arial, sans-serif; font-size: 16px;"
                dominant-baseline="middle"
          >
            <tspan dy="0">
              {{ monthsLeft }} months left
            </tspan>
          </text>
          <text
                :x="textXPosition"
                :y="textYPosition + 60"
                text-anchor="middle"
                :fill="'#262525'"
                style="font-family: 'Poiret One Regular', Arial, sans-serif; font-size: 16px;"
                dominant-baseline="middle"
          >
            <tspan dy="0">
              {{ daysLeft }} days left
            </tspan>
          </text>
        </g>
        <!-- Add your logo here -->
        <image
          xlink:href="@/assets/nhan-initialen-logo-schwarz.svg"
          :x="svgWidth/2"
          :y="textYPosition + 100"
          width="50"
          height="50"
        />
      </svg>
    </div>
  </div>
</template>

<script>
const LIFE_EXPECTANCY = 81;
const MIN_RECT_SIZE = 50;
const RECT_MARGIN = 15;
const GRID_SIZE = 20;
const HEIGHT_OFFSET = 350;

export default {
  name: 'LifeTime',

  data() {
    return {
      name: '',
      birthdate: '',
      years: [],
      isAnimating: false,
      hasStarted: false,
      isComplete: false,
      rectsPerRow: 9,
      containerWidth: 800,
      svgWidth: 0,
      svgHeight: 0,
      rectWidth: 0,
      rectHeight: 0,
      gridSize: GRID_SIZE,
      birthdateError: null
    }
  },

  computed: {
    isValid() {
      return this.name && this.birthdate
    },

    age() {
      if (!this.birthdate) return 0
      const birth = new Date(this.birthdate)
      const today = new Date()
      return today.getFullYear() - birth.getFullYear()
    },

    gridWidth() {
      return (this.rectsPerRow * this.rectWidth) + (RECT_MARGIN * (this.rectsPerRow + 1));
    },

    gridHeight() {
      const totalRows = Math.ceil(this.years.length / this.rectsPerRow);
      return (totalRows * this.rectHeight) + (RECT_MARGIN * (totalRows + 1)) + (HEIGHT_OFFSET/2);
    },

    textYPosition() {
      const totalRows = Math.ceil(this.years.length / this.rectsPerRow);
      return (totalRows * (this.rectHeight + RECT_MARGIN)) + RECT_MARGIN + 200; // Add extra padding
    },

    textXPosition() {
      return this.svgWidth / 2; // Center horizontally
    },

    monthsLeft() {
      const monthsLived = this.age * 12;
      const totalMonths = LIFE_EXPECTANCY * 12;
      return totalMonths - monthsLived;
    },

    daysLeft() {
      const daysLived = this.age * 365.25; // Approximate days per year
      const totalDays = LIFE_EXPECTANCY * 365.25;
      return Math.floor(totalDays - daysLived);
    },

    currentDate() {
      const today = new Date();
      return today.toISOString().split('T')[0];
    },

    maxBirthdate() {
      const today = new Date();
      const maxYear = today.getFullYear() - 130;
      const maxDate = new Date(maxYear, today.getMonth(), today.getDate());
      return maxDate.toISOString().split('T')[0];
    }
  },

  methods: {
    calculateDimensions: function () {
      this.containerWidth = this.$el?.querySelector('.paper-container')?.clientWidth || 800;
      const padding = 40;
      const availableWidth = this.containerWidth - (2 * padding);

      this.rectsPerRow = Math.floor(availableWidth / (MIN_RECT_SIZE + RECT_MARGIN));

      this.rectWidth = (availableWidth - (RECT_MARGIN * (this.rectsPerRow + 1))) / this.rectsPerRow;
      this.rectHeight = this.rectWidth;

      this.svgWidth = availableWidth;
      const totalRows = Math.ceil(LIFE_EXPECTANCY / this.rectsPerRow);
      this.svgHeight = totalRows * (this.rectHeight + RECT_MARGIN) + RECT_MARGIN + HEIGHT_OFFSET;
    },

    async startVisualization() {
      if (!this.isValid) return

      this.hasStarted = true
      this.isAnimating = true
      this.isComplete = false

      // Initialize years array based on user's age
      this.years = Array(Math.max(LIFE_EXPECTANCY, this.age)).fill(null).map((_, index) => ({
        passed: index < this.age,
        visible: false
      }))

      // Animate rectangles appearing one by one
      for (let i = 0; i < this.years.length; i++) {
        this.years[i].visible = true
        await new Promise(resolve => setTimeout(resolve, 50))
      }

      this.isAnimating = false
      this.isComplete = true
    },

    reset() {
      this.years = []
      this.isAnimating = false
      this.hasStarted = false
      this.isComplete = false
      this.birthdate = null
      this.name = null
    },

    getX(index) {
      const column = index % this.rectsPerRow;
      return RECT_MARGIN + column * (this.rectWidth + RECT_MARGIN);
    },

    getY(index) {
      const row = Math.floor(index / this.rectsPerRow);
      return RECT_MARGIN + row * (this.rectHeight + RECT_MARGIN);
    },

    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },

    validateBirthdate() {
      requestAnimationFrame(() => {
        if (this.birthdate && (this.birthdate > this.currentDate || this.birthdate < this.maxBirthdate)) {
          this.birthdate = '';
          this.birthdateError = 'Please enter a valid birthdate. The maximum age is 130 years.';
        } else {
          this.birthdateError = null;
        }
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.calculateDimensions();
      window.addEventListener('resize', this.debounce(this.calculateDimensions, 250));
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.debounce(this.calculateDimensions, 250));
  }
}
</script>

<style scoped>
.life-grid {
  @apply w-full h-auto bg-white border-gray-200;
}

.paper-container > div > h3, #final-message > h4 {
  @apply text-black;
}

.year-rect {
  transition: opacity 0.3s ease;
  rx: 0px;
  ry: 0px;
}

button:disabled {
  @apply bg-teal-200 text-black cursor-not-allowed border-none;
}

input {
  @apply text-black;
}
#calendar-icon > path {
  stroke: black;
}
</style>