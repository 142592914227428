<template>
  <button
    class="mr-1"
    type="button"
    @click="$emit('click')"
    :title="title"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'FractalButton',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script> 