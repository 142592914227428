<template>
  <section class="customer-feedback">
    <h2>Why Clients Work With Me</h2>
    <div class="testimonials-grid">
      <div class="testimonial-card">
        <div class="testimonial-header">
          <div class="testimonial-image">
            <img src="@/assets/jens.jpeg" alt="Jens Stork" class="rounded-full w-16 h-16 object-cover" />
          </div>
          <div class="testimonial-author">
            <h4 class="mb-0">Jens Stork</h4>
            <p class="text-sm text-gray-400">CTO, Colour Connections GmbH</p>
          </div>
        </div>
        <blockquote class="mt-4">
          "Working with Nhân is extremely positive, characterized by agility, thoughtful planning, thorough preparation and result orientation down to the smallest detail. Despite sometimes highly complex relationships, he finds his way around specific industry-related requirements without losing sight of the big picture. We continue to enjoy working this way - here's to many more great and exciting projects!"
        </blockquote>
      </div>

      <div class="testimonial-card">
        <div class="testimonial-header">
          <div class="testimonial-image">
            <img src="@/assets/klaus-schlitt.jpeg" alt="Klaus Schlitt" class="rounded-full w-16 h-16 object-cover" />
          </div>
          <div class="testimonial-author">
            <h4 class="mb-0">Klaus Schlitt</h4>
            <p class="text-sm text-gray-400">CEO & Founder, BD-A GmbH</p>
          </div>
        </div>
        <blockquote class="mt-4">
          "The long-term cooperation with Nhân since 2017 has proven to be extremely valuable for the BD-A GmbH. With him at our side, we can say with full confidence that we have an extremely reliable partner."
        </blockquote>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Testimonials'
}
</script>

<style scoped>
.customer-feedback {
  padding: 4rem 0;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.testimonial-card {
  padding: 2rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.testimonial-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

blockquote {
  font-style: italic;
  color: #e0e0e0;
}
</style>