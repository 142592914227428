<template>
  <div class="container mx-auto">
    <Navbar />
    <div class="content">
      <router-view></router-view>
    </div>
    <Footer />
  </div>

</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
#app {
  font-family: 'DM Sans Regular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content {
  @apply bg-gray-900;
  margin: 0 auto;
  max-width: 1120px;
}
#app > .container {
  max-width: 1120px;
}
</style>
