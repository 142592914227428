<template>
 <div id="imprint">
     <h4 class="font-bold mb-2 block">imprint</h4>
   <span class="font-bold">Nhân Vũ </span><br>
   Kiedricher Str. 26<br>
   55218 Ingelheim<br>
   Germany<br>
   <span>me@nhanvu.de</span><br>
 </div>
</template>

<script>
    export default {
        name: "Imprint"
    }
</script>

<style scoped>

</style>
