<template>
  <div id="fractal-dragon" class="fractal-content flex flex-row">
    <div class="flex-none-screen border-e bg-white text-black">
      <div class="px-4 py-6 text-left">
        <ul class="mt-6 space-y-1">
          <li>
            <label class="block text-sm font-medium text-gray-900 mb-1">
              Resolution
              <input
                v-model.number="resolution"
                type="number"
                class="w-2/3 mb-2 p-1 border rounded"
                :max="20"
                :min="1"
              />
            </label>

            <label class="block text-sm font-medium text-gray-900 mb-1">
              Animation Speed
              <input
                v-model.number="animationSpeed"
                type="range"
                class="w-2/3 mb-2"
                :min="0.1"
                :max="30.1"
                :step="1"
              />
            </label>

            <ColorPicker
              v-model="color"
            />

            <button
              class="w-2/3 block mb-2"
              @click="drawWithAnimation"
              type="button"
              title="Draw the fractal with animation"
            >
              Draw Animated
            </button>
            <button
              class="w-2/3 block mb-2"
              @click="drawAsPolyline"
              type="button"
              title="Draw the fractal as polyline"
            >
              Draw Polyline
            </button>
            <button
              class="w-2/3 block"
              @click="resetCanvas"
              type="button"
              title="Reset the canvas"
            >
              Reset
            </button>
          </li>
        </ul>
      </div>
      <p class="p-4 grid w-64 place-content-center rounded-lg text-xs text-gray-800 text-left">
        The Dragon Curve is a fractal that is created by folding a strip of paper in half repeatedly, 
        then unfolding it to right angles. The resulting shape is complex and self-similar.
      </p>
    </div>
    <div class="flex-auto flex-col p-10 bg-gray-300">
      <h1 class="w-full text-left">Dragon Curve</h1>
      <div class="mb-2">
        <FractalButton 
          @click="zoomIn" 
          title="Zoom in to the fractal"
        >+</FractalButton>
        <FractalButton 
          @click="zoomOut" 
          title="Zoom out of to the fractal"
        >-</FractalButton>
      </div>
      <div class="w-full" ref="drawing"></div>
    </div>
  </div>
</template>

<script>
import '@/assets/fractals.css'
import { SVG } from '@svgdotjs/svg.js'
import '@svgdotjs/svg.panzoom.js'
import ColorPicker from '@/components/common/ColorPicker.vue'
import FractalButton from '@/components/common/FractalButton.vue'

// Helper function to unwrap proxy values
const unwrapProxy = (value) => {
  if (Array.isArray(value)) {
    return value.map(unwrapProxy)
  }
  return value
}

// Utility functions
const calculateColor = (index, colorRate) => {
  const r = Math.floor(index / colorRate) % 256
  const g = Math.floor(index / colorRate / 256) % 256
  const b = Math.floor(index / colorRate / 256 / 256) % 256
  return `rgb(${r}, ${g}, ${b})`
}

const calculateDragonCurve = (xs, ys, xn, yn, resolution, dir = 1, depth = 0, maxDepth = 10) => {
  xs = Number(xs)
  ys = Number(ys)
  xn = Number(xn)
  yn = Number(yn)
  resolution = Number(resolution)
  dir = Number(dir)

  const dx = xn - xs
  const dy = yn - ys
  const length = Math.sqrt(dx * dx + dy * dy)

  if (length < resolution || depth >= maxDepth) {
    return [[xs, ys, xn, yn]]
  }

  const xmid = (xn + xs) / 2
  const ymid = (yn + ys) / 2
  const xx = xmid - (ymid - ys) * dir
  const yy = ymid + (xmid - xs) * dir

  const leftCurve = calculateDragonCurve(xs, ys, xx, yy, resolution, 1, depth + 1, maxDepth)
  const rightCurve = calculateDragonCurve(xx, yy, xn, yn, resolution, -1, depth + 1, maxDepth)

  return [...leftCurve, ...rightCurve]
}

const calculateDragonCurvePoints = (xs, ys, xn, yn, resolution, maxDepth = 15) => {
  const points = []
  let i = 0

  function recurse(xs, ys, xn, yn, dir, depth) {
    if (depth >= maxDepth) {
      points.push([xs, ys])
      i++
      return
    }

    const xMiddle = (xn + xs) / 2
    const yMiddle = (yn + ys) / 2
    const xlen = xMiddle - xs
    const ylen = yMiddle - ys
    const distance = Math.sqrt(xlen * xlen + ylen * ylen)

    if (distance < resolution) {
      points.push([xs, ys])
      i++
      return
    }

    const xx = xMiddle - ylen * dir
    const yy = yMiddle + xlen * dir
    recurse(xs, ys, xx, yy, 1, depth + 1)
    recurse(xx, yy, xn, yn, -1, depth + 1)
  }

  recurse(xs, ys, xn, yn, 1, 0)
  return { points, iterations: i }
}

const CANVAS_WIDTH = 785
const CANVAS_HEIGHT = 768
const CURVE_OFFSET = 200

export default {
  name: 'DragonCurve',
  components: {
    ColorPicker,
    FractalButton,
  },
  data() {
    return {
      draw: null,
      resolution: 4,
      animationSpeed: 0.1,
      color: '#000000',
      currentZoomIn: 0,
      lines: [],
      points: [],
      currentLineIndex: 0,
      animationInterval: null,
      colorRate: 8
    }
  },
  mounted() {
    this.initializeCanvas()
  },
  methods: {
    initializeCanvas() {
      this.draw = SVG()
        .id('fractal-dragon-curve')
        .addTo(this.$refs.drawing)
        .size(CANVAS_WIDTH, CANVAS_HEIGHT)
        .viewbox(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
      this.zoom()
    },
    validateResolution() {
      if (this.resolution > 40) {
        alert('Sorry the resolution is too high and you would not see anything meaningful from the dragon curve.')
        return false
      }
      return true
    },

    validateLine(line) {
      if (!Array.isArray(line) || line.length !== 4) {
        return false
      }
      const [x1, y1, x2, y2] = line.map(Number)
      return !isNaN(x1) && !isNaN(y1) && !isNaN(x2) && !isNaN(y2)
    },

    drawWithAnimation() {
      this.clear()
      if (!this.validateResolution()) return

      const centerX = CANVAS_WIDTH / 2
      const centerY = CANVAS_HEIGHT / 2
      const maxDepth = Math.floor(15 - this.resolution / 2)
      
      const calculatedLines = calculateDragonCurve(
        centerX - CURVE_OFFSET,
        centerY,
        centerX + CURVE_OFFSET,
        centerY,
        this.resolution * 2,
        1,
        0,
        maxDepth
      )
      
      this.lines = unwrapProxy(calculatedLines)
      
      if (!Array.isArray(this.lines) || this.lines.length === 0) {
        return
      }
      
      this.currentLineIndex = 0
      this.startAnimation()
      this.zoom()
    },
    startAnimation() {
      if (this.animationInterval) {
        clearInterval(this.animationInterval)
      }

      this.animationInterval = setInterval(() => {
        if (this.currentLineIndex >= this.lines.length) {
          clearInterval(this.animationInterval)
          return
        }

        const line = unwrapProxy(this.lines[this.currentLineIndex])
        
        if (!this.validateLine(line)) {
          this.currentLineIndex++
          return
        }

        const [x1, y1, x2, y2] = line.map(Number)
        const color = calculateColor(this.currentLineIndex, this.colorRate)

        this.draw.line(x1, y1, x2, y2)
          .stroke({ color, width: 1 })

        this.currentLineIndex++
      }, this.animationSpeed * 100)
    },
    drawAsPolyline() {
      this.clear()
      const maxDepth = Math.floor(15 - this.resolution / 3)
      const result = calculateDragonCurvePoints(
        300, 
        150, 
        850, 
        300, 
        this.resolution,
        maxDepth
      )
      this.points = result.points
      this.draw.polyline(this.points)
        .fill('none')
        .stroke({ 
          color: this.color, 
          width: 1, 
          linecap: 'round', 
          linejoin: 'round' 
        })
      this.zoom()
    },
    clear() {
      if (this.animationInterval) {
        clearInterval(this.animationInterval)
      }
      this.draw.clear()
      this.lines = []
      this.points = []
      this.currentLineIndex = 0
    },
    zoom() {
      const options = { zoomMin: 0.3, zoomMax: 100 }
      this.draw.panZoom(options)
    },
    zoomIn() {
      this.currentZoomIn += 0.5
      this.draw.panZoom().zoom(this.currentZoomIn)
    },
    zoomOut() {
      if (this.currentZoomIn > 1) {
        this.currentZoomIn -= 0.5
        this.draw.panZoom().zoom(this.currentZoomIn)
      }
      if (this.currentZoomIn === 1) {
        this.currentZoomIn = 0.5
        this.draw.panZoom().zoom(this.currentZoomIn)
      }
    },
    resetCanvas() {
      this.resolution = 4
      this.clear()
    }
  },
  beforeUnmount() {
    if (this.animationInterval) {
      clearInterval(this.animationInterval)
    }
  }
}
</script> 