<template>
  <div class="design-page mx-auto pt-8 text-white">
    <section class="hero">
      <div class="hero-content p-20">
        <h1 class="font-bold mb-8 text-center w-full">Design Experiments & Generative Art</h1>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <!-- Introduction Section -->
          <div class="md:mb-0">
            <h2 class="mb-4">What are Design Experiments</h2>
            <p class="mb-4">
              Design experiments are explorations at the intersection of art, technology, and user experience.
              They help us understand how we can create more meaningful and engaging digital experiences.
            </p>
          </div>

          <!-- Personal Connection Section -->
          <div>
            <h2 class="mb-4">Why I Love Design</h2>
            <p class="mb-4">
              As both a developer and designer, I'm passionate about creating experiences that bridge
              aesthetics and functionality. These experiments allow me to explore
              new ways of human-computer interaction.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Interactive Visualizations -->
    <section class="bg-black pb-5">
      <h2 class="mb-4">Interactive Experiments</h2>
      <p class="mb-4">
        Explore my collection of design experiments. (more to come)
      </p>
      
      <!-- Life Time Visualization -->
      <div class="bg-gray-900 p-8 mt-20">
        <h3 class="mb-4">Life Time Visualization</h3>
        <p class="mb-4 w-8/12 mx-auto">
          An interactive visualization that helps you understand the passage of time in your life.
          Enter your details to see your life's timeline represented through animated squares.
        </p>
      </div>
      <div class="bg-gray-900">
        <LifeTime />
        <button 
          v-if="canDownloadPdf"
          class="btn btn-large btn-white mb-5"
          @click="downloadLifeTimePdf"
        >
          Download as PDF
        </button>
      </div>
    </section>

    <!-- Applications Section -->
    <section class="p-10">
      <h2>Applications in Design</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div class="mb-3">
          <h3>In User Experience</h3>
          <p>Creating more intuitive and engaging ways for users to interact with digital products.</p>
        </div>
        <div class="mb-3">
          <h3>In Data Visualization</h3>
          <p>Transforming complex data into meaningful and beautiful visual representations.</p>
        </div>
        <div class="mb-3">
          <h3>In Generative Art</h3>
          <p>Exploring the intersection of code, creativity, and randomness to create unique artwork.</p>
        </div>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="cta p-10 mx-auto">
      <div class="border-2 p-10 border-teal-400">
        <h2 class="text-teal-400 text-3xl mb-4">Have an Experiment in Mind?</h2>
        <p class="w-3/4 mx-auto">
          Whether you're looking to create innovative user experiences or explore the possibilities
          of generative design, let's collaborate on bringing your ideas to life.
        </p>
        <ConsultationButton/>
      </div>
    </section>
  </div>
</template>

<script>
import LifeTime from '@/components/generative-ai/LifeTime.vue'
import ConsultationButton from '@/components/ConsultationButton.vue'
import { exportSvgToPdf } from '@/utils/pdfExport'

export default {
  name: 'DesignIntroduction',
  components: {
    LifeTime,
    ConsultationButton
  },
  data() {
    return {
      canDownloadPdf: false
    }
  },
  methods: {
    async downloadLifeTimePdf() {
      const success = await exportSvgToPdf({
        svgId: 'life-visualization-svg',
        title: '',
        filename: 'life-timeline.pdf'
      });

      if (!success) {
        alert('Sorry, there was an error generating the PDF.');
      }
    },
    checkSvgContent() {
      const svg = document.getElementById('life-visualization-svg');
      const finalMessage = svg?.querySelector('#final-message');
      // Check if SVG exists and has the final message element
      this.canDownloadPdf = svg && finalMessage;
    }
  },
  mounted() {
    // Initial check
    this.checkSvgContent();
    // Watch for changes in SVG content
    const observer = new MutationObserver(this.checkSvgContent);
    const svg = document.getElementById('life-visualization-svg');
    if (svg) {
      observer.observe(svg, { 
        childList: true, 
        subtree: true 
      });
    }
  },
  beforeUnmount() {
    // Clean up observer if it exists
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script> 